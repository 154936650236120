import lozad from 'lozad'
import imgRoute from '../img/alejandroMadariaga.jpg'
import 'particles.js'

init()
function init() {
    loadProfileImg()
    createBackground()
}

function loadProfileImg() {
    let imgEl = document.querySelector('#profileImg')
    imgEl.dataset.src = imgRoute

    const observer = lozad('.lozad')
    observer.observe()
}

function createBackground() {
    window.particlesJS('particles-js', {
        particles: {
            number: { value: 200, density: { enable: true, value_area: 800 } },
            color: { value: '#ffffff' },
            shape: {
                type: 'circle',
                stroke: { width: 0, color: '#000000' },
                polygon: { nb_sides: 5 },
            },
            opacity: {
                value: 0.5,
                random: true,
                anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                },
            },
            size: {
                value: 4.008530152163807,
                random: true,
                anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
            },
            line_linked: {
                enable: true,
                distance: 78.91476416322726,
                color: '#ffffff',
                opacity: 0.20517838682439088,
                width: 1,
            },
            move: {
                enable: true,
                speed: 0.8,
                direction: 'none',
                random: true,
                straight: false,
                out_mode: 'out',
                bounce: false,
                attract: { enable: false, rotateX: 600, rotateY: 1200 },
            },
        },
        interactivity: {
            detect_on: 'canvas',
            events: {
                onhover: { enable: false, mode: 'repulse' },
                onclick: { enable: false, mode: 'push' },
                resize: true,
            },
            modes: {
                grab: { distance: 400, line_linked: { opacity: 1 } },
                bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                },
                repulse: { distance: 200, duration: 0.4 },
                push: { particles_nb: 4 },
                remove: { particles_nb: 2 },
            },
        },
        retina_detect: true,
    })
}
